<template>
  <b-list-group-item button @click="routeToBehaviourView">
    <h6 class="text-primary">
      {{ bn.name }}
    </h6>
    <small>Click to see in {{ bn.bt_name ? bn.bt_name : 'behaviour tree' }}</small>
  </b-list-group-item>
</template>

<script>
export default {
  name: 'ListGroupItemBN',
  props: {
    bn: {
      required: true,
      type: Object,
    },
  },
  methods: {
    routeToBehaviourView() {
      this.$router.push(
        {
          name: 'joint_mbse_tree_focus',
          params: { behaviourTreeId: this.bn.bt ? this.bn.bt : this.bn.in_bt },
          query: { focus: this.bn.id },
        },
      )
    },
  },
}
</script>
