<template>
  <b-modal
    :id="issuesCreateId"
    title="Raise and Allocate Issue"
    size="lg"
    @show="onShow"
    @ok="submit"
  >
    <b-form>
      <b-row>
        <b-col>
          <label for="name">Issue</label>
          <tip-tap-editor
            id="name"
            v-model="name"
            placeholder="Briefly describe the Issue..."
            :allow-image-upload="false"
            min-height="6"
            max-height="6"
          />
        </b-col>
      </b-row>

      <b-row class="mt-1">
        <b-col>
          <label for="ddlClassification">Classification</label>
          <b-form-select
            id="ddlClassification"
            v-model="classification"
            :options="issueClassifications"
          />
        </b-col>
      </b-row>

      <b-row class="mt-1">
        <b-col>
          <label for="ddlSeverity">Severity</label>
          <b-form-select
            id="ddlSeverity"
            v-model="severity"
            :options="issueSeverities"
          />
        </b-col>
      </b-row>

      <b-row class="mt-1">
        <b-col>
          <label for="ddlStatus">Status</label>
          <b-form-select
            id="ddlStatus"
            v-model="status"
            :options="issueStatuses"
          />
        </b-col>
      </b-row>

      <b-row class="mt-1">
        <b-col>
          <label for="description">Additional Details</label>
          <tip-tap-editor
            id="description"
            v-model="description"
            placeholder="Give some more context to the Issue if required..."
            :allow-image-upload="false"
            min-height="8"
            max-height="8"
          />
        </b-col>
      </b-row>
    </b-form>

    <template v-slot:modal-footer="{ok, cancel}">
      <b-button variant="success" :disabled="loading_status" @click="ok()">
        <span v-if="loading_status">
          <b-spinner small type="grow" />
          Raising...
        </span>

        <span v-else>
          Raise Issue
        </span>
      </b-button>

      <b-button @click="cancel()">
        Discard
      </b-button>
    </template>

  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import TipTapEditor from '@/components/Forms/TipTapEditor/TipTapEditor.vue'

export default {
  components: {
    TipTapEditor,
  },
  props: {
    issuesCreateId: {
      type: String,
      default: 'create-and-allocate-issue-modal',
    },
    scope: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    name: '',
    description: '',
    classification: '',
    severity: '',
    status: '',
    loading_status: false,
  }),
  computed: {
    ...mapGetters({
      issueClassifications: 'constants/issueClassifications',
      issueSeverities: 'constants/issueSeverities',
      issueStatuses: 'constants/issueStatuses',
    }),
  },
  methods: {
    onShow() {
      this.severity = this.$store.state.constants.defaultIssueSeverity.id
      this.classification = this.$store.state.constants.defaultIssueClassification.id
      this.status = this.$store.state.constants.defaultIssueStatus.id
    },
    submit() {
      const payload = {
        model: this.$store.state.model.id,
        name: this.name,
        description: this.description,
        classification: this.classification,
        severity: this.severity,
        status: this.status,
      }
      this.$http
        .post('/api/v2/issues', payload)
        .then(({ data }) => {
          this.loading_status = false
          if (this.scope === '') {
            console.log('Normal Issue')
            this.$emit('associateIssue', data)
          } else {
            console.log('Execution Issue')
            this.$emit('associateIssue_exec', data)
          }

          this.$bvModal.hide('create-and-allocate-issue-modal')
        })
        .catch(r => console.error(r))
    },
  },
}
</script>
