<template>
  <div>
    <span class="font-medium-2">Linked {{ label }}</span>

    <b-list-group
      v-if="entityArray.length > 0"
    >
      <ListGroupItemBN
        v-for="(item,index) in entityArray"
        :key="index"
        :bn="item"
      />
    </b-list-group>

    <div v-else>
      <span class="ml-3 font-small-3 text-muted">No linked {{ label }}</span>
    </div>
  </div>
</template>

<script>
import { BButton, BListGroup, VBTooltip } from 'bootstrap-vue'
import ListGroupItemBN from '@/components/Forms/ListGroupItems/ListGroupItemBN.vue'

export default {
  name: 'ListGroupBehaviour',
  components: {
    BListGroup,
    ListGroupItemBN,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    entityArray: {
      type: Array,
      required: true,
    },
    modal: {
      type: String,
      required: true,
    },
  },
}
</script>
