<template>
  <div>
    <div v-if="!hideHeader">
      <b-button
        v-b-tooltip.hover.left="`Update linked ${label}`"
        variant="flat-success"
        size="sm"
        class="mb-25"
        @click="$bvModal.show(modal)"
      >
        <feather-icon icon="LinkIcon" />
      </b-button>
      <span class="font-medium-2">Linked {{ label }}</span>
    </div>

    <b-list-group
      v-if="entityArray.length > 0"
    >
      <!--<pre>{{ entityArray }}</pre>-->
      <ListGroupItemIssue
        v-for="(item,index) in entityArray"
        :key="index"
        :issue="item"
      />
    </b-list-group>

    <div v-else>
      <span class="ml-3 font-small-3 text-muted">No linked {{ label }}</span>
    </div>
  </div>
</template>

<script>
import { BButton, BListGroup, VBTooltip } from 'bootstrap-vue'
import ListGroupItemIssue from '@/components/Forms/ListGroupItems/ListGroupItemIssue.vue'

export default {
  name: 'ListGroupIssue',
  components: {
    BButton,
    BListGroup,
    ListGroupItemIssue,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    entityArray: {
      type: Array,
      required: true,
    },
    modal: {
      type: String,
      required: true,
    },
    hideHeader: {
      type: Boolean,
      required: false,
    },
  },
}
</script>
