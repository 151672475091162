<template>
  <b-modal
    id="test-execution-modal"
    title="Log Test Execution"
    @ok="submit"
  >
    <!-- build environment -->
    <b-row>
      <b-col>
        <b-form-group>
          <h5>Build Environment</h5>
          <b-form-select v-model="selectedBuild">
            <b-form-select-option v-for="build in builds" :key="build.key" :value="build.key">
              {{ build.label }}
            </b-form-select-option>
          </b-form-select>
        </b-form-group>
      </b-col>
    </b-row>

    <!-- date and time -->
    <b-row>
      <b-col>
        <b-form-group>
          <h5>Execution Date & Time</h5>
          <flat-pickr
            v-model="selectedDateTime"
            class="form-control"
            :config="{ enableTime: true, dateFormat: 'd-M-Y H:iK'}"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <!-- result -->
    <b-row>
      <b-col>
        <b-form-group>
          <h5>Result</h5>
          <b-form-select v-model="selectedResult">
            <b-form-select-option v-for="result in results" :key="result.key" :value="result.key">
              {{ result.label }}
            </b-form-select-option>
          </b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import { BCol, BFormGroup, BRow } from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'

export default {
  name: 'TestExecution',
  components: {
    BRow,
    BCol,
    BFormGroup,
    flatPickr,
  },
  props: {
    testUuid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      builds: [],
      results: [
        { key: 'passed', label: 'Passed' },
        { key: 'failed', label: 'Failed' },
      ],
      selectedDateTime: null,
      selectedResult: null,
      selectedBuild: null,
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      const params = { model: this.$store.state.model.id }
      this.$http
        .get('/api/v2/domain_model/get_build_configurations', { params })
        .then(({ data }) => {
          this.builds = data.map(x => ({ key: x.id, label: x.name }))
        })
    },
    submit() {
      const params = {
        test_uuid: this.testUuid,
      }
      const payload = {
        build: this.selectedBuild,
        datetime: this.selectedDateTime,
        result: this.selectedResult,
      }
      this.$http.post('/api/v2/tests/log_test_execution', payload, { params })
        .then(r => {
          console.log(r)
          this.$emit('updateExecutionList')
          this.clearForm()
        })
        .catch(r => {
          console.error('Failed to log Test Execution result')
          console.error(r)
          this.clearForm()
        })
    },
    clearForm() {
      this.selectedBuild = null
      this.selectedDateTime = null
      this.selectedResult = null
    },
  },
}
</script>

<style lang="scss">
@import 'src/@core/scss/vue/libs/vue-flatpicker.scss';
</style>
