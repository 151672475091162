<template>
  <b-list-group-item
    v-b-tooltip.hover.top="`Click to open test`"
    button
    @click="openTest"
  >
    <div class="w-100 d-inline-flex justify-content-between">
      <div>
        <h6 class="text-primary font-weight-bolder">
          {{ test.ref_id || test.id }}
        </h6>
        <p class="mb-0 ">
          {{ test.name }}
        </p>
      </div>
      <div>
        <b-badge v-if="test.latest_result && test.latest_result.toLowerCase() === 'passed'" variant="success">
          Passed
        </b-badge>
        <b-badge v-if="test.latest_result && test.latest_result.toLowerCase() === 'failed'" variant="danger">
          Failed
        </b-badge>
        <b-badge v-else variant="secondary">
          Not Executed
        </b-badge>
      </div>
    </div>
    <hr class="w-75">

    <div v-html="test_text" />
  </b-list-group-item>
</template>

<script>
export default {
  name: 'ListGroupItemTest',
  props: {
    test: {
      type: Object,
      required: true,
    },
  },
  computed: {
    test_text() {
      const testWording = (this.test.test)
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/GIVEN/g, '<font color="#f7991c">GIVEN</font>')
        .replace(/WHEN/g, '<font color="#f7991c">WHEN</font>')
        .replace(/THEN/g, '<font color="#f7991c">THEN</font>')
        .replace(/AND/g, '<font color="#f7991c">AND</font>')

      return `<pre><code>${testWording}</code></pre>`
    },
  },
  methods: {
    openTest() {
      this.$router.push(
        {
          name: 'model_test_focus',
          params: { testId: this.test.id },
        },
      )
    },
  },
}
</script>
