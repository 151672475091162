<template>
  <b-modal
    id="associate-requirements-modal"
    title="Link Requirements"
    size="xl"
    ok-title="Update Links"
    ok-variant="primary"
    cancel-variant="outline-secondary"
    cancel-title="Close"
    no-close-on-backdrop
    @ok="onSubmit"
  >
    <div id="requirementAssociator">
      <div class="d-flex m-25">
        <!-- Available Reqs -->
        <div class="w-50 d-flex flex-column">
          <div>
            <h6 class="d-inline">
              Specification
            </h6>
            <span class="float-right font-small-2 text-info">
              Clear selection to show all requirements
            </span>
            <b-form-group>
              <v-select
                v-model="selectedSpecification"
                :disabled="loadingRequirements"
                :loading="loadingRequirements"
                label="title"
                :options="specifications"
              >
                <template #spinner="{ loadingRequirements }">
                  <div
                    v-if="loadingRequirements"
                    style="border-left-color: rgba(88, 151, 251, 0.71)"
                    class="vs__spinner"
                  />
                </template>
              </v-select>
            </b-form-group>
          </div>
          <div class="flex-grow-1 d-flex flex-column">
            <div class="w-100 d-inline-flex justify-content-between">
              <h6>
                <b-badge variant="info" class="mr-1">
                  {{ requirements.length }}
                </b-badge>
                <span v-if="requirements.length !== 1">Available Requirements</span>
                <span v-else>Requirement</span>
              </h6>
              <b-button-group class="w-25">
                <b-button
                  size="sm"
                  variant="flat-info"
                  @click="fetchAvailableRequirements(selectedSpecification)"
                >
                  <feather-icon icon="RefreshCcwIcon" class="pr-25" />Refresh
                </b-button>
                <b-button
                  size="sm"
                  variant="flat-primary"
                  @click="selectedRequirements = []; requirementSearch = ''"
                >
                  Clear Selection
                </b-button>
              </b-button-group>
            </div>
            <b-form-input
              v-model="requirementSearch"
              placeholder="Search..."
              style="border-bottom-left-radius: 0; border-bottom-right-radius: 0;"
            />
            <b-form-select
              v-model="selectedRequirements"
              :disabled="loadingRequirements"
              class="h-100"
              style="min-height: 35rem; border-top-left-radius: 0; border-top-right-radius: 0; border-top: 0"
              multiple
            >
              <b-select-option v-for="req in requirements" :key="req.value" :value="req.value">
                {{ req.display_id }}: <div class="w-75 text-ellipsis" :title="req.object_text"> {{ req.object_text }} </div>
              </b-select-option>
            </b-form-select>
          </div>
        </div>

        <!-- Assign button -->
        <div class="d-flex flex-column align-self-center mx-50">
          <b-button
            v-b-popover.hover.top="'Assign requirements'"
            variant="flat-success"
            :disabled="!selectedRequirements.length"
            @click="addSelected"
          >
            <feather-icon icon="ArrowRightIcon" size="24" />
          </b-button>
        </div>

        <!-- Allocated Requirements -->
        <div class="w-50">
          <h6>
            <b-badge variant="info" class="mr-25">
              {{ linkedRequirements.length }}
            </b-badge>
            <span>Linked Requirements</span>
          </h6>
          <vue-perfect-scrollbar
            v-if="linkedRequirements.length > 0"
            class="scroll-area"
            :settings="{
              maxScrollbarLength: 60,
              wheelPropagation: false,
            }"
          >
            <b-list-group>
              <b-list-group-item
                v-for="(req, index) in linkedRequirements"
                :key="index"
              >
                <div class="d-flex flex-column">
                  <div class="d-flex justify-content-between pb-0">
                    <div class="pt-25 pl-25 mb-0 pb-0">
                      <abbr
                        :title="req.display_id"
                        class="mr-1 text-ellipsis"
                      >
                        <span class="font-weight-bolder text-primary"> {{ `${req.display_id}.` }}</span> {{ `${(req.object_text || req.text)}` }}
                      </abbr>
                    </div>
                    <div class="w-50 d-inline-flex flex-row-reverse">
                      <b-button
                        variant="flat-danger"
                        size="sm"
                        class="p-25 ml-3"
                        @click.stop="removeAllocation(req.id, index)"
                      >
                        <feather-icon icon="XIcon" />
                      </b-button>
                    </div>
                  </div>
                </div>
              </b-list-group-item>
            </b-list-group>
          </vue-perfect-scrollbar>
          <div v-else>
            <p class="mt-1 ml-1 text-muted">
              No linked Requirements...
            </p>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import store from '@/store'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  name: 'RequirementAssociator',
  /**
   * This form handles the trace allocation for Requirements.
   * It can be used to update a single or multiple requirements.
   */
  directives: {
    Ripple,
  },
  components: {
    vSelect,
    VuePerfectScrollbar,
  },
  props: {
    isModal: {
      type: Boolean,
      default: false,
    },
    initialLinkedRequirements: {
      type: Array,
      required: true,
    },
    removeFn: {
      type: Function,
      required: false,
      default: () => false,
    },
    updateFn: {
      type: Function,
      required: true,
    },
  },
  setup(props, context) {
    // Specification selection --
    const selectedSpecification = ref('')
    const specifications = ref([])
    const fetchSpecifications = () => {
      store
        .dispatch('specifications/fetchSpecificationsSimple')
        .then(() => {
          specifications.value = store.state.specifications.specifications
        })
    }
    fetchSpecifications()
    // -- ./specification selection

    // Available requirements --
    const selectedRequirements = ref([])
    const requirementsCoreList = ref([])
    const requirements = ref([])
    const loadingRequirements = ref(false)
    const fetchAvailableRequirements = specification => {
      loadingRequirements.value = true
      store
        .dispatch('requirements/updateRequirementOptions', specification?.id)
        .then(() => {
          requirementsCoreList.value = []
          store.state.requirements.requirement_options.forEach(a => {
            requirementsCoreList.value.push(a)
          })
          requirements.value = requirementsCoreList.value
        })
        .catch(e => {
          console.error(e)
        })
        .finally(() => {
          loadingRequirements.value = false
        })
    }
    // -- ./available requirements

    // Requirement context --
    const linkedRequirements = ref([])
    linkedRequirements.value = props.initialLinkedRequirements
    // -- ./requirement context

    // Form elements --
    const requirementSearch = ref('')
    const removeAllocation = async (reqId, index) => {
      try {
        linkedRequirements.value.splice(index, 1)
        if (props.removeFn) props.removeFn(reqId, linkedRequirements.value)
      } catch (error) {
        console.error('[ERR] Failed to delete requirement allocation')
        context.root.$swal({
          icon: 'error',
          title: 'Error removing requirement allocation',
          text: error.response.data.detail,
          customClass: {
            confirmButton: 'btn btn-danger',
          },
        })
      }
    }

    const addSelected = () => {
      // Add the Requirement to 'Traced' list if it doesn't already exist.
      selectedRequirements.value.forEach(
        x => {
          const requirement = requirements.value.find(y => y.value === x)
          if (linkedRequirements.value.filter(tI => tI.id === x).length === 0) {
            const item = {
              id: requirement.value,
              display_id: requirement.display_id,
              object_text: requirement.object_text,
              text: requirement.object_text,
            }
            linkedRequirements.value.push(item)
          }
        },
      )
    }
    // -- ./form elements

    const onSubmit = () => {
      const payload = linkedRequirements.value.map(t => t.id)
      props.updateFn(payload)
    }
    // -- ./form submission

    // Watchers
    watch(selectedSpecification, newSpec => {
      if (newSpec) {
        fetchAvailableRequirements(newSpec)
      } else {
        fetchAvailableRequirements()
      }
    })
    watch(requirementSearch, newValue => {
      requirements.value = requirementsCoreList.value
      if (newValue !== '') {
        // Filter Requirements list by text (display_id & object_text) or value (requirement id)
        requirements.value = requirements.value.filter(a => (
          a.text.toLowerCase().includes(newValue.toLowerCase())
          || a.value.toLowerCase() === newValue.toLowerCase()
        ))
      }
    })

    return {
      // Setup
      linkedRequirements,
      specifications,
      requirements,
      loadingRequirements,
      fetchAvailableRequirements,

      // Selected items
      selectedSpecification,
      selectedRequirements,

      // Form
      requirementSearch,
      addSelected,
      removeAllocation,

      // Submit
      onSubmit,
    }
  },
}
</script>

<style lang="scss" scoped>
.scroll-area {
  position: relative;
  margin: auto;
  max-height: 50vh;
}
.text-ellipsis {
  max-width: 170rem;
  text-overflow: ellipsis;
  overflow-wrap: normal;
  hyphens: auto;
  overflow: clip;
  display: inline-block;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import '~@core/scss/vue/libs/vue-sweetalert.scss';
</style>
