<template>
  <div>
    <b-list-group-item
      class="entity"
      @contextmenu.prevent="$refs.menu.open"
    >
      <h6 class="text-primary">
        {{ instant.name }}
      </h6>
      <div v-if="instant.description">
        <div v-html="instant.description" />
      </div>
    </b-list-group-item>

  </div>
</template>

<script>
import VueContext from 'vue-context'
import { BLink } from 'bootstrap-vue'

export default {
  name: 'ListGroupItemInstance',
  components: {
  },
  props: {
    instant: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
    }
  },
  computed: {
  },
  methods: {
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-context.scss';

.btn-flat-primary:focus {
  background-color: unset;
}

.entity {
  background-color: rgba(22, 29, 49, 0.02) !important;
  border: 1px dashed;
  border-left: 0;
  border-right: 0;
}

.entity:hover {
  cursor: pointer;
  background-color: rgba(22, 29, 49, 0.05) !important;
}

.dark-layout {
  .entity {
    background-color: rgba(22, 29, 49, 0.3) !important;
  }

  .entity:hover {
    background-color: rgba(22, 29, 49, 1) !important;
  }
}
</style>
